import {AnswerStatus, QuestionDifficulty} from '@hconnect/common/types'
import CloseIcon from '@mui/icons-material/Close'
import {Box, IconButton, Paper, Typography} from '@mui/material'
import {useSnackbar} from 'notistack'
import React, {FC, useCallback, useMemo, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useParams} from 'react-router'

import {
  ANSWER_STATUS_LABEL_COLOR_MAP,
  doesAnswerCompleteCategory,
  questionsToQuestionItemsMap
} from '../../common/domain/questionnaire'
import {PathParameters} from '../../common/routing'
import {usePlantQuestions} from '../../hooks/api/usePlantQuestions'
import {useSubmitAnswer} from '../../hooks/api/useSubmitAnswer'
import {useStringSearchParameter} from '../../hooks/urlParameters/useSearchParameter'
import {useQuestionState} from '../../hooks/useQuestionState'
import {QuestionAnswer} from '../../types'
import {Question} from '../../types/backend.types'
import {ActionCardSkeleton} from '../actions/ActionsCard'
import {DataFetchingPlaceholder} from '../common/DataFetchingPlaceholder'
import {LevelCompletion} from '../levelReward/LevelCompletion'
import {QuestionDetail} from '../questionnaire/QuestionDetail'
import {QuestionTable} from '../questionnaire/questionTable'

type QuestionListSideCardProps = {
  difficulty: QuestionDifficulty
  customQuestionsFilterFunction?: (value: Question) => boolean
}

const ANSWER_STATUS_TRANSLATION_KEY_MAP: Record<
  Exclude<AnswerStatus, AnswerStatus.Empty>,
  string
> = {
  [AnswerStatus.Positive]: 'answersStatistics.positive',
  [AnswerStatus.Negative]: 'answersStatistics.negative',
  [AnswerStatus.NotAvailable]: 'answersStatistics.notAvailable'
}

export const QuestionListSideCard: FC<QuestionListSideCardProps> = ({
  difficulty,
  customQuestionsFilterFunction
}) => {
  const {plantId} = useParams<Pick<PathParameters, 'plantId'>>()
  if (!plantId) throw new Error('The plantId is not set in the URL.')
  const {t} = useTranslation()
  const {data, isInitialLoading} = usePlantQuestions()
  const [selectedQuestionId, setQuestionId] = useStringSearchParameter('selectedQuestionId')
  const [{activeAnswerStatus, activeDifficulty}, {setActiveAnswerStatus}] = useQuestionState()
  const {enqueueSnackbar} = useSnackbar()

  const questions = useMemo(() => {
    const filteredData = customQuestionsFilterFunction
      ? data?.filter(customQuestionsFilterFunction)
      : data
    return questionsToQuestionItemsMap(filteredData ?? [])
      [difficulty].filter(({answer}) =>
        activeAnswerStatus === AnswerStatus.Empty
          ? !answer || answer === AnswerStatus.Empty
          : answer === activeAnswerStatus
      )
      .filter(({difficulty}) => difficulty === activeDifficulty)
  }, [activeAnswerStatus, activeDifficulty, customQuestionsFilterFunction, data, difficulty])

  const [showCompletionDialog, setShowCompletionDialog] = useState(false)
  const onSuccess = useCallback(
    (result: QuestionAnswer) => {
      enqueueSnackbar(t('questionnaire.questionSubmitted.success'), {
        variant: 'success'
      })
      if (doesAnswerCompleteCategory(result, data ?? [])) {
        setShowCompletionDialog(true)
      }
    },
    [data, enqueueSnackbar, t]
  )
  const {mutate: submitAnswer} = useSubmitAnswer(onSuccess)
  const onAnswerSelected = (id: string, answer: AnswerStatus) => {
    submitAnswer({
      plantId,
      questionId: id,
      answer
    })
  }

  const selectedQuestion = useMemo(
    () => questions.find(({id}) => id === selectedQuestionId),
    [questions, selectedQuestionId]
  )

  return isInitialLoading ? (
    <DataFetchingPlaceholder
      isLoading={isInitialLoading}
      height="100%"
      loader={<ActionCardSkeleton />}
      errorMessage={t('errors.questionsNotFetched')}
    />
  ) : (
    <Paper data-test-id="question-list-side-card" sx={{height: '100%', p: 2.5}}>
      {selectedQuestion ? (
        <QuestionDetail
          selectedQuestion={selectedQuestion}
          onClose={() => setQuestionId(undefined)}
          onSelected={onAnswerSelected}
        />
      ) : (
        <>
          <Box display="flex" mb={4} mt={1.5} alignItems="center" justifyContent="space-between">
            <Typography variant="h3">
              {activeAnswerStatus && activeAnswerStatus !== AnswerStatus.Empty ? (
                <>
                  {t('questionSideCars.label.allAnsweredWith')}{' '}
                  <Box component="span" color={ANSWER_STATUS_LABEL_COLOR_MAP[activeAnswerStatus]}>
                    {t(ANSWER_STATUS_TRANSLATION_KEY_MAP[activeAnswerStatus])}
                  </Box>
                </>
              ) : (
                t('questionSideCars.label.allUnansweredQuestions')
              )}{' '}
              ({questions.length})
            </Typography>
            <IconButton
              sx={{mr: 1.5, color: 'primary.main'}}
              onClick={() => setActiveAnswerStatus(undefined)}
              data-test-id="question-list-side-card-close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <QuestionTable
            setQuestionId={setQuestionId}
            questionItems={questions}
            onAnswerSelected={onAnswerSelected}
          />
        </>
      )}

      {showCompletionDialog && (
        <LevelCompletion
          open={showCompletionDialog}
          sectionCompleted={difficulty}
          onOpenChange={(open) => setShowCompletionDialog(open)}
        />
      )}
    </Paper>
  )
}
