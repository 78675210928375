import {useQuery} from '@tanstack/react-query'
import {NavigateFunction, useNavigate} from 'react-router-dom'

import {QueryKeyName, getLocationsStats} from '../../common/backend'
import {handleError, retryFunction} from '../apiErrorHandler'

export const useAllLocationsStats = () => {
  const navigate: NavigateFunction = useNavigate()
  return useQuery([QueryKeyName.GetLocationsStats], () => getLocationsStats(), {
    onError: (err) => handleError(err, navigate),
    retry: retryFunction
  })
}
