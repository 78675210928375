import {useQuery, UseQueryResult} from '@tanstack/react-query'

import {QueryKeyName, getActionsBySectionId} from '../../common/backend'
import {ActionItem} from '../../types'
import {usePlantId} from '../urlParameters/usePlantId'

export const useSectionActions = (sectionId: string): UseQueryResult<ActionItem[]> => {
  const plantId = usePlantId()

  return useQuery([QueryKeyName.GetActionItems, plantId, sectionId], async () => {
    return await getActionsBySectionId({plantId, sectionId})
  })
}
