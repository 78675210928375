import {useQuery} from '@tanstack/react-query'
import React, {createContext, useContext, useMemo, useState} from 'react'
import {usePromise} from 'react-use'

import {
  QueryKeyName,
  isUserLoggedIn,
  startLoginProcess,
  startLogoutProcess,
  tryInitialize
} from '../common/backend'
import {PomInitData} from '../common/backend/authentication'
import {AuthContext} from '../types'

const Context = createContext<AuthContext | undefined>(undefined)

export const useAuthContext = (): AuthContext => {
  const context = useContext(Context)
  if (!context) {
    throw new Error('useAuthContext used outside of AuthProvider')
  }
  return context
}

export const AuthProvider = ({children}: {children: React.ReactNode}) => {
  const [context, setContext] = useState<PomInitData | undefined>(undefined)
  const promise = usePromise()

  const {isInitialLoading} = useQuery<PomInitData | undefined>(
    [QueryKeyName.LoadUser, context],
    () => tryInitialize(),
    {
      enabled: !isUserLoggedIn(context?.user),
      onSuccess: (data) => {
        setContext(data)
      }
    }
  )

  const value = useMemo(() => {
    const logout = async () => {
      await promise(startLogoutProcess())
      setContext(undefined)
    }

    return {
      whiteList: context?.whiteList,
      user: context?.user,
      login: startLoginProcess,
      logout,
      isLoading: isInitialLoading,
      isLoggedIn: isUserLoggedIn(context?.user)
    }
  }, [isInitialLoading, promise, context])

  return <Context.Provider value={value}>{children}</Context.Provider>
}
