import {useMutation} from '@tanstack/react-query'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'

import {downloadPlantActions} from '../../common/backend/actions'
import {downloadBlob} from '../../common/downloadBlob'

export const usePlantActionsDownload = (plantId: string) => {
  const {enqueueSnackbar} = useSnackbar()
  const {t} = useTranslation()

  return useMutation(() => downloadPlantActions(plantId), {
    onSuccess: (blob) => {
      downloadBlob(blob, `${plantId}_actions.xlsx`)
    },
    onError: () => {
      enqueueSnackbar(t('errors.excelDownloadFailed'), {variant: 'error'})
    }
  })
}
