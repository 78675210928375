import {useQuery} from '@tanstack/react-query'

import {QueryKeyName, getQuestions} from '../../common/backend'
import {usePlantId} from '../urlParameters/usePlantId'
import {useTopicId} from '../urlParameters/useTopicId'

export const useQuestions = () => {
  const plantId = usePlantId()
  const topicId = useTopicId()

  return useQuery([QueryKeyName.GetQuestions, plantId, topicId], () =>
    getQuestions({plantId, topicId})
  )
}
