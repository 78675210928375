import {useMutation} from '@tanstack/react-query'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'

import {downloadHierarchySubmissions} from '../../common/backend/locationStats'
import {downloadBlob} from '../../common/downloadBlob'

export const useHierarchyDownload = () => {
  const {enqueueSnackbar} = useSnackbar()
  const {t} = useTranslation()

  return useMutation(() => downloadHierarchySubmissions(), {
    onSuccess: (blob) => {
      downloadBlob(blob, 'plant-hierarchy_submissions.xlsx')
    },
    onError: () => {
      enqueueSnackbar(t('errors.excelDownloadFailed'), {variant: 'error'})
    }
  })
}
