import {UserDto} from '@hconnect/common/types'
import {useQuery} from '@tanstack/react-query'
import {AxiosError} from 'axios'

import {QueryKeyName, getUser} from '../../common/backend'
import {useAuthContext} from '../../providers/AuthProvider'

export const useUser = () => useAuthContext().user

export const useUserById = (id?: string) => {
  return useQuery<UserDto | undefined, AxiosError>([QueryKeyName.GetUser, id], async () => {
    if (id) {
      return await getUser({id})
    }
  })
}
