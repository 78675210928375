import {useQuery, UseQueryResult} from '@tanstack/react-query'

import {QueryKeyName, getActionsByTopicId} from '../../common/backend'
import {ActionItem} from '../../types'
import {usePlantId} from '../urlParameters/usePlantId'

export const useTopicActions = (topicId: string): UseQueryResult<ActionItem[]> => {
  const plantId = usePlantId()

  return useQuery([QueryKeyName.GetActionItems, plantId, topicId], async () => {
    return await getActionsByTopicId({plantId, topicId})
  })
}
