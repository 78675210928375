import {useQuery} from '@tanstack/react-query'
import {useState, useEffect, useMemo} from 'react'

import {QueryKeyName, getAttachmentFiles} from '../../common/backend'
import {Attachment} from '../../types/backend.types'

export const useFileHref = (file?: File): string | undefined => {
  const [href, setHref] = useState<string>()

  useEffect(() => {
    if (file) {
      const href = URL.createObjectURL(file)
      setHref(href)
      return () => URL.revokeObjectURL(href)
    }
  }, [file])

  return href
}

interface AttachmentHrefs {
  fileHref?: string
  previewFileHref?: string
}

export const useAttachmentFiles = (attachment: Attachment): AttachmentHrefs => {
  const {data} = useQuery([QueryKeyName.GetAttachmentFiles, attachment.id], async () => {
    return await getAttachmentFiles(attachment)
  })

  const fileHref = useFileHref(data?.file)
  const previewHref = useFileHref(data?.previewFile)

  return useMemo(
    () => ({fileHref: fileHref, previewFileHref: previewHref}),
    [fileHref, previewHref]
  )
}
