import {
  AnswerStatus,
  DifficultySummaries,
  QuestionDifficulty,
  UserDto
} from '@hconnect/common/types'
import {getCurrentDifficulty} from '@hconnect/common/utils'
import {
  PageTitle,
  PageContainer,
  buttonOnBlueSx,
  customThemeConstants,
  BackToLink
} from '@hconnect/uikit/src/lib2'
import {FileDownload} from '@mui/icons-material'
import {LoadingButton} from '@mui/lab'
import {Box, Stack} from '@mui/material'
import React, {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, NavigateFunction, useNavigate} from 'react-router-dom'

import {PLANT_SECTION, ROOT} from '../common/routing'
import {DataFetchingPlaceholder} from '../components/common/DataFetchingPlaceholder'
import {CompletionCard} from '../components/completion/CompletionCard'
import {PlantSideCard} from '../components/plantSideCard'
import {QuestionListSideCard} from '../components/questionListSideCard'
import {ReferencesLink} from '../components/questionnaire/ReferencesLink'
import {SectionTopContainer} from '../containers/SectionTopContainer'
import {usePlantActions} from '../hooks/api/usePlantActions'
import {usePlantSubmissionDownload} from '../hooks/api/usePlantSubmissionDownload'
import {usePlantId} from '../hooks/urlParameters/usePlantId'
import {useStringSearchParameter} from '../hooks/urlParameters/useSearchParameter'
import {usePlantContext} from '../hooks/usePlantContext'
import {QuestionStateProvider, useQuestionState} from '../hooks/useQuestionState'
import {Section} from '../types'
import {countAllSubmissions, SubmissionSummary} from '../utils'

type PlantSectionProps = {
  sections: Section[]
  difficultyScoreSummaries: DifficultySummaries
  responsible?: UserDto
}

const PlantSection = ({sections, responsible, difficultyScoreSummaries}: PlantSectionProps) => {
  const {data: actions, status} = usePlantActions()
  const plantId = usePlantId()
  const navigate: NavigateFunction = useNavigate()
  const [, setQuestionId] = useStringSearchParameter('selectedQuestionId')

  const [{activeAnswerStatus, activeDifficulty}, {setActiveDifficulty, setActiveAnswerStatus}] =
    useQuestionState()

  const onScoreSectionClick = useCallback(
    (status?: AnswerStatus) => {
      setActiveAnswerStatus(status)
      setQuestionId(undefined)
    },
    [setActiveAnswerStatus, setQuestionId]
  )
  const onDifficultySectionClick = useCallback(
    (difficulty: QuestionDifficulty) => {
      setActiveDifficulty(difficulty)
      setQuestionId(undefined)
    },
    [setActiveDifficulty, setQuestionId]
  )

  const submissionSummary = useMemo<SubmissionSummary>(() => {
    return countAllSubmissions(sections, activeDifficulty)
  }, [sections, activeDifficulty])

  return (
    <SectionTopContainer
      actionLoadingStatus={status}
      isLoading={!actions || !sections}
      sideCardComponent={
        activeAnswerStatus ? (
          <QuestionListSideCard difficulty={submissionSummary.difficulty} />
        ) : (
          <PlantSideCard
            sections={sections}
            onSectionRowClick={(id: string) => {
              navigate(generatePath(PLANT_SECTION, {plantId, sectionId: id}))
            }}
            actions={(actions || []).filter((a) => a.status !== 'done')}
          />
        )
      }
    >
      <CompletionCard
        activeDifficulty={activeDifficulty}
        onScoreSectionClick={onScoreSectionClick}
        onDifficultySectionClick={onDifficultySectionClick}
        responsiblePerson={responsible}
        submissionSummary={submissionSummary}
        difficultySummaries={difficultyScoreSummaries}
      />
    </SectionTopContainer>
  )
}

export const DashboardPage: React.FC = () => {
  const {t} = useTranslation()
  const {data, isInitialLoading: isLoadingPlantData} = usePlantContext()
  const plantId = usePlantId()
  const navigate = useNavigate()

  const {isLoading: downloadIsLoading, mutate: downloadPlantSubmissions} =
    usePlantSubmissionDownload(plantId)

  return (
    <PageContainer>
      <Box display={'flex'} justifyContent="space-between" alignItems="center">
        <Stack>
          <BackToLink
            targetName={t('navigation.backToPlantOverview')}
            target={generatePath(ROOT)}
            navigate={navigate}
          />
          <PageTitle mb={0}>{t('dashboardPage.title')}</PageTitle>
          {data?.guidanceLink && <ReferencesLink guidanceLink={data.guidanceLink} />}
        </Stack>
        <Box mb={customThemeConstants().defaultGridSpacing}>
          <LoadingButton
            variant="contained"
            onClick={() => downloadPlantSubmissions()}
            startIcon={<FileDownload />}
            loading={downloadIsLoading}
            loadingPosition={'start'}
            sx={buttonOnBlueSx}
          >
            {t('button.export')}
          </LoadingButton>
        </Box>
      </Box>
      {data ? (
        <QuestionStateProvider
          data={{activeDifficulty: getCurrentDifficulty(data.difficultySummaries)}}
        >
          <PlantSection
            sections={data.sections}
            difficultyScoreSummaries={data.difficultySummaries}
            responsible={data.responsible}
          />
        </QuestionStateProvider>
      ) : (
        <DataFetchingPlaceholder
          isLoading={isLoadingPlantData}
          errorMessage={t('errors.sectionsNotFetched')}
        />
      )}
    </PageContainer>
  )
}
