import {useQuery} from '@tanstack/react-query'

import {QueryKeyName, getPlantQuestions} from '../../common/backend'
import {usePlantId} from '../urlParameters/usePlantId'

export const usePlantQuestions = () => {
  const plantId = usePlantId()

  return useQuery([QueryKeyName.GetPlantQuestions, plantId], () => getPlantQuestions(plantId))
}
