import {useQuery, UseQueryResult} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {NavigateFunction, useNavigate} from 'react-router-dom'

import {QueryKeyName, getPlant} from '../../common/backend'
import {Plant} from '../../types'
import {handleError, retryFunction} from '../apiErrorHandler'

export const usePlant = (plantId: string): UseQueryResult<Plant, AxiosError> => {
  const navigate: NavigateFunction = useNavigate()
  return useQuery(
    [QueryKeyName.GetPlant, plantId],
    async () => {
      return await getPlant(plantId)
    },
    {
      onError: (err) => handleError(err, navigate),
      retry: retryFunction,
      refetchOnWindowFocus: false
    }
  )
}
