import {getCurrentDifficulty} from '@hconnect/common/utils'
import {
  PageTitle,
  PageContainer,
  TextOnBackground,
  BackToLink,
  buttonOnBlueSx
} from '@hconnect/uikit/src/lib2'
import {FileDownload} from '@mui/icons-material'
import {Box, Button, Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router-dom'

import {DASHBOARD} from '../common/routing'
import {DataFetchingPlaceholder} from '../components/common/DataFetchingPlaceholder'
import {ReferencesLink, TITLE_MARGIN_BOTTOM} from '../components/questionnaire/ReferencesLink'
import {SectionContainer} from '../containers/SectionContainer'
import {useSection} from '../hooks/api/useSection'
import {useSectionSubmissionsDownload} from '../hooks/api/useSectionSubmissionsDownload'
import {usePlantId} from '../hooks/urlParameters/usePlantId'
import {useSectionId} from '../hooks/urlParameters/useSectionId'
import {QuestionStateProvider} from '../hooks/useQuestionState'

export const PlantSectionPage: React.FC = () => {
  const {t} = useTranslation()
  const plantId = usePlantId()
  const sectionId = useSectionId()
  const {data: section, isInitialLoading} = useSection()
  const {isLoading: sectionSubmissionsDownloadIsLoading, mutate: downloadSectionSubmissions} =
    useSectionSubmissionsDownload(plantId, sectionId)

  const navigate = useNavigate()

  return (
    <PageContainer>
      <BackToLink
        targetName={t('navigation.backTo', {target: t('navigation.dashboard')})}
        target={generatePath(DASHBOARD, {plantId})}
        navigate={navigate}
      />
      <Stack pb={2} direction={'row'} justifyContent={'space-between'}>
        <Stack>
          <PageTitle
            data-test-id="plant-section-page-title"
            mb={section?.guidanceLink ? 0 : TITLE_MARGIN_BOTTOM}
          >
            {section?.name}
            <TextOnBackground variant="caption" ml={1} contrast="low">
              {section?.id}
            </TextOnBackground>
          </PageTitle>
          {section?.guidanceLink && <ReferencesLink guidanceLink={section.guidanceLink} />}
        </Stack>
        <Box>
          <Button
            variant={'text'}
            color={'primary'}
            sx={buttonOnBlueSx}
            disabled={sectionSubmissionsDownloadIsLoading}
            onClick={() => downloadSectionSubmissions()}
          >
            <FileDownload />
          </Button>
        </Box>
      </Stack>

      {section ? (
        <QuestionStateProvider
          data={{activeDifficulty: getCurrentDifficulty(section.difficultySummaries)}}
        >
          <SectionContainer section={section} />
        </QuestionStateProvider>
      ) : (
        <DataFetchingPlaceholder
          isLoading={isInitialLoading}
          errorMessage={t('errors.sectionNotFetched')}
        />
      )}
    </PageContainer>
  )
}
