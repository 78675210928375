import {useMutation, useQueryClient} from '@tanstack/react-query'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'

import {QueryKeyName, addComment} from '../../common/backend'
import {usePlantId} from '../urlParameters/usePlantId'

interface AddCommentArgs {
  questionId: string
  comment: string
}

export const useAddComment = () => {
  const {t} = useTranslation()
  const plantId = usePlantId()
  const client = useQueryClient()
  const {enqueueSnackbar} = useSnackbar()

  return useMutation(
    ({questionId, comment}: AddCommentArgs) => addComment({plantId, questionId, comment}),
    {
      onSuccess: () => {
        void client.invalidateQueries([QueryKeyName.GetComments])
        enqueueSnackbar(t('comments.added.success'), {
          variant: 'success'
        })
      },
      onError: () => {
        enqueueSnackbar(t('comments.added.error'), {variant: 'error'})
      }
    }
  )
}
