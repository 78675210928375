import {customThemeConstants} from '@hconnect/uikit/src/lib2'
import {Grid} from '@mui/material'
import {QueryStatus} from '@tanstack/react-query'
import React, {ReactNode} from 'react'
import {useTranslation} from 'react-i18next'

import {ActionCardSkeleton} from '../components/actions/ActionsCard'
import {DataFetchingPlaceholder} from '../components/common/DataFetchingPlaceholder'

interface SectionTopContainerProps {
  actionLoadingStatus: QueryStatus
  sideCardComponent: ReactNode
  children: ReactNode
  isLoading: boolean
}

export const SectionTopContainer: React.FC<SectionTopContainerProps> = ({
  actionLoadingStatus,
  sideCardComponent,
  isLoading,
  children
}) => {
  const {t} = useTranslation()

  return (
    <Grid container spacing={customThemeConstants().defaultGridSpacing}>
      <Grid item xs={12} md={6} xl={5}>
        {children}
      </Grid>
      <Grid item xs={12} md={6} xl={7}>
        {!isLoading ? (
          <>{sideCardComponent}</>
        ) : (
          <DataFetchingPlaceholder
            height="100%"
            loader={<ActionCardSkeleton />}
            isLoading={actionLoadingStatus === 'loading'}
            errorMessage={t('errors.actionsNotFetched')}
          />
        )}
      </Grid>
    </Grid>
  )
}
