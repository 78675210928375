import {hpTheme} from '@hconnect/uikit/src/lib2'
import {CssBaseline, ThemeProvider} from '@mui/material'
import {DefaultOptions, QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {AxiosError} from 'axios'
import {SnackbarProvider} from 'notistack'
import React from 'react'
import {HelmetProvider} from 'react-helmet-async'
import {useTranslation} from 'react-i18next'
import {BrowserRouter as Router} from 'react-router-dom'

import {APP_NAME} from './common/constants'
import {HelmetHead} from './components/helmet/HelmetHead'
import {Main} from './Main'
import {AuthProvider} from './providers/AuthProvider'
import {POMToursProvider} from './providers/POMToursProvider'

const isNetworkError = (error: AxiosError) => error.message === 'Network Error'
const retryTries = 1
const retryFunction = (failureCount: number, error: AxiosError): boolean => {
  return failureCount < retryTries && isNetworkError(error)
}

const defaultOptions: DefaultOptions<AxiosError> = {
  queries: {
    retry: retryFunction
  },
  mutations: {
    retry: retryFunction
  }
}

const queryClient = new QueryClient({
  defaultOptions: defaultOptions as DefaultOptions
})

export const App: React.FC = () => {
  const {t} = useTranslation()

  return (
    <HelmetProvider>
      <HelmetHead title={APP_NAME} description={t('appDescription')} />
      <ThemeProvider theme={hpTheme}>
        <CssBaseline />
        <SnackbarProvider>
          <QueryClientProvider client={queryClient}>
            <POMToursProvider>
              <Router>
                <AuthProvider>
                  <Main />
                </AuthProvider>
              </Router>
            </POMToursProvider>
          </QueryClientProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </HelmetProvider>
  )
}
