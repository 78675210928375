import {useQueryClient, useMutation} from '@tanstack/react-query'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'

import {QueryKeyName, deleteAction} from '../../common/backend'
import {usePlantId} from '../urlParameters/usePlantId'

export const useDeleteAction = () => {
  const {t} = useTranslation()
  const plantId = usePlantId()
  const client = useQueryClient()
  const {enqueueSnackbar} = useSnackbar()

  return useMutation((actionId: string) => deleteAction({plantId, actionId}), {
    onSuccess: () => {
      void client.invalidateQueries([QueryKeyName.GetActionItems])
      enqueueSnackbar(t('deleteAction.afterSubmit.success'), {
        variant: 'success'
      })
    },
    onError: () => {
      enqueueSnackbar(t('deleteAction.afterSubmit.error'), {variant: 'error'})
    }
  })
}
