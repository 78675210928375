import {getCurrentDifficulty} from '@hconnect/common/utils'
import {PageTitle, PageContainer, BackToLink, TextOnBackground} from '@hconnect/uikit/src/lib2'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useNavigate} from 'react-router-dom'

import {getSectionTopic} from '../common/domain/section'
import {PLANT_SECTION} from '../common/routing'
import {DataFetchingPlaceholder} from '../components/common/DataFetchingPlaceholder'
import {ReferencesLink, TITLE_MARGIN_BOTTOM} from '../components/questionnaire/ReferencesLink'
import {TopicContainer} from '../containers/TopicContainer'
import {useSection} from '../hooks/api/useSection'
import {usePlantId} from '../hooks/urlParameters/usePlantId'
import {useSectionId} from '../hooks/urlParameters/useSectionId'
import {useTopicId} from '../hooks/urlParameters/useTopicId'
import {QuestionStateProvider} from '../hooks/useQuestionState'

export const PlantSubSectionPage: React.FC = () => {
  const {t} = useTranslation()
  const plantId = usePlantId()
  const sectionId = useSectionId()
  const topicId = useTopicId()
  const {data: section, isInitialLoading} = useSection()
  const topic = section ? getSectionTopic(section, topicId) : undefined

  const navigate = useNavigate()

  return (
    <PageContainer>
      <BackToLink
        targetName={section?.name ?? ''}
        target={generatePath(PLANT_SECTION, {plantId, sectionId})}
        navigate={navigate}
      />
      <PageTitle ml={3} mb={topic?.guidanceLink ? 0 : TITLE_MARGIN_BOTTOM}>
        {topic?.name}
        <TextOnBackground variant="caption" ml={1} contrast="low">
          {topic?.id}
        </TextOnBackground>
      </PageTitle>
      {topic?.guidanceLink && <ReferencesLink guidanceLink={topic.guidanceLink} />}
      {topic ? (
        <QuestionStateProvider
          data={{activeDifficulty: getCurrentDifficulty(topic.difficultySummaries)}}
        >
          <TopicContainer topic={topic} />
        </QuestionStateProvider>
      ) : (
        <DataFetchingPlaceholder
          isLoading={isInitialLoading}
          errorMessage={t('errors.topicNotFetched')}
        />
      )}
    </PageContainer>
  )
}
