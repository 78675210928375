import {useQuery} from '@tanstack/react-query'

import {QueryKeyName, getComments} from '../../common/backend'
import {usePlantId} from '../urlParameters/usePlantId'

export const useComments = (questionId: string) => {
  const plantId = usePlantId()
  return useQuery([QueryKeyName.GetComments, plantId, questionId], () =>
    getComments({plantId, questionId})
  )
}
