import {useQueryClient, useMutation} from '@tanstack/react-query'
import {useSnackbar} from 'notistack'
import {useTranslation} from 'react-i18next'

import {
  QueryKeyName,
  assignTopicResponsible,
  assignSectionResponsible,
  assignPlantResponsible
} from '../../common/backend'
import {usePlantId} from '../urlParameters/usePlantId'

interface UseAssignResponsibleArgs {
  userId: string
  plantId?: string
  sectionId?: string
  topicId?: string
}

export const useAssignResponsible = (onSuccess?: () => void) => {
  const {t} = useTranslation()
  const plantId = usePlantId()
  const client = useQueryClient()
  const {enqueueSnackbar} = useSnackbar()
  return useMutation(
    ({sectionId, userId, topicId}: UseAssignResponsibleArgs) =>
      topicId
        ? assignTopicResponsible({plantId, topicId, userId})
        : sectionId
          ? assignSectionResponsible({plantId, sectionId, userId})
          : assignPlantResponsible({plantId, userId}),
    {
      onSuccess: () => {
        void client.invalidateQueries([QueryKeyName.GetPlant])
        void client.invalidateQueries([QueryKeyName.GetSection])
        void client.invalidateQueries([QueryKeyName.GetTopic])
        onSuccess?.()
      },
      onError: () => {
        enqueueSnackbar(t('assignResponsible.afterSubmit.error'), {variant: 'error'})
      }
    }
  )
}
